module services {
    export module master {
        export class sKUCustomerService implements interfaces.master.ISKUCustomerService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getAllSKU(entityId: number, customerId: number, skuCode: string, description: string, show: number, numberRecords: number, pageNumber: number): ng.resource.IResourceClass<interfaces.master.ISKUCustomer> {

                return this.$resource<interfaces.master.ISKUCustomer>(this.ENV.DSP_URL + "SKUCustomer/GetAllSKU", {
                    entityId: entityId,
                    customerId: customerId,
                    skuCode: skuCode,
                    description: description,
                    show: show,
                    numberRecords: numberRecords,
                    pageNumber: pageNumber
                });
            }

            saveList(itemList: Array<interfaces.master.ISKUCustomer>): ng.IPromise<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SKUCustomer/Save").save(itemList).$promise;
            }
        }
    }
    angular.module("app").service("sKUCustomerService", services.master.sKUCustomerService);
}